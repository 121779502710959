<template>
  <v-main app class="">
    <div class="d-flex flex-column flex-grow-1 h-full">
      <AppHeader />
      <AppContainer />
      <AppFooter />
    </div>
  </v-main>
</template>

<script>
export default {
  name: "LayoutScreen",
  components: {
    AppHeader: () => import("@/layouts/component/Header"),
    AppContainer: () => import("@/layouts/component/Container"),
    AppFooter: () => import("@/layouts/component/Footer"),
  }
};
</script>